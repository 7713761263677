import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { 
  Stack, 
  Paper, 
  Box, 
  Typography, 
  Tabs, 
  Tab, 
  Grid,
  Button, 
  Skeleton, 
  Accordion,
  AccordionSummary,
  AccordionDetails,
  LinearProgress
} from '@mui/material'
import { getCategoryCaseCount, getOwnerUnitCaseCount, getCategorySatisfaction, getOwnerUnitSatisfaction } from '@/api/getRankAPI'
import { getCases, getCasesFirst } from '@/api/getCasesAPI'
import { getReportCasesExcelData } from '@/api/getReportDataAPI'
import UseDateRangeStore from "@/hooks/UseDateRangeStore";
import UseOwnerUnitStore from "@/hooks/UseOwnerUnitStore";
import UseSubCategoryStore from "@/hooks/UseSubCategoryStore";
import UseSearchStore from "@/hooks/UseSearchStore";
import UseCaseDataStore from "@/hooks/UseCaseDataStore"
import UseSatisfactionStore from '@/hooks/UseSatisfactionStore'
import { ReactComponent as RankLinkIcon } from '@/assets/rankLink.svg'
import { ReactComponent as ExportIcon } from '@/assets/export.svg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RankBarChart from '@/components/RankBarChart'

const CustomTab = styled(Tab)((theme) => ({
  '&.MuiButtonBase-root': {
    padding: 0,
    color: '#0A0A0A',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 600,
    width: '96px',
    minHeight: '37px',
    position: 'relative',
    zIndex: 2,
    // minWidth: 'auto',
    // minHeight: 'auto',
    borderRadius: '16px 0 0 16px',
    // marginRight: '4px',
    // padding: '4px 20px',
    transition: 'border .3s, color .3s',
    border: '2px solid #D4D4D4',
    '&:hover': {
      border: '2px solid #52697A',
      color: '#52697A'
    },
    '&:last-child': {
      borderRadius: '0 16px 16px 0',
      marginLeft: '-2px',
      position: 'relative',
      zIndex: 1,
    }
  },
  '&.Mui-selected': {
    border: '2px solid #52697A',
    color: '#52697A',
    '&:last-child': {
      borderRadius: '0 16px 16px 0',
      marginLeft: '-2px',
      position: 'relative',
      zIndex: 2,
    }
  }
}))

const CustomRankTab = styled(Tab)(() => ({
  '&.MuiButtonBase-root': {
    padding: 0,
    color: '#0A0A0A',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 600,
    minWidth: 'auto',
    minHeight: 'auto',
    borderRadius: '99px',
    marginRight: '4px',
    padding: '4px 20px',
    transition: 'all .3s',
    '&:hover': {
      backgroundColor: '#fff',
      boxShadow: '10px 0 20px 0 rgba(0, 0, 0, 0.05)'
    }
  },
  '&.Mui-selected': {
    backgroundColor: '#fff',
    boxShadow: '10px 0 20px 0 rgba(0, 0, 0, 0.05)'
  }
}))

function RankStatistics() {
  const [ categoryType, setCategoryType ] = useState('category');
  const [ rankType, setRankType ] = useState('times');
  const [ expanded, setExpanded ] = useState(false);
  const [ categoryCaseCount, setCategoryCaseCount ] = useState([]);
  const [ ownerUnitCaseCount, setOwnerUnitCaseCount ] = useState([]);
  const [ categorySatisfaction, setCategorySatisfaction ] = useState({});
  const [ ownerUnitSatisfaction, setOwnerUnitSatisfaction ] = useState({});
  const [ triggerExport, setTriggerExport ] = useState(false);
  const [ triggerFromOtherPage, setTriggerFromOtherPage ] = useState(true);

  const { formattedMinDate, formattedMaxDate } = UseDateRangeStore()
  const { subOwnerUnitsSelected, updateOwnerUnitSelected, clearOwnerUnitSelected } = UseOwnerUnitStore()
  const { subCategoriesSelected, updateSubCategorySelected, clearSubCategorySelected } = UseSubCategoryStore()
  const { clearSearchSelected } = UseSearchStore()
  const { caseData, setCaseData } = UseCaseDataStore()
  const { updateSatisfaction } = UseSatisfactionStore()

  const { data: caseFirstChunkData } = useQuery({
    queryFn: () => getCasesFirst(formattedMinDate, formattedMaxDate, {}, {}, '', []),
    queryKey: ['casesFirst', formattedMinDate, formattedMaxDate],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const { data: categoryCaseCountData, isLoading: isLoadingCategoryCaseCountData, isError: isErrorCategoryCaseCountData, refetch: refetchCategoryCaseCountData } = useQuery({
    queryFn: () => getCategoryCaseCount(formattedMinDate, formattedMaxDate),
    queryKey: ['categoryCaseCountData', formattedMinDate, formattedMaxDate],
    refetchOnWindowFocus: false,
    staleTime: 60000
  })
  
  const { data: ownerUnitCaseCountData, isLoading: isLoadingOwnerUnitCaseCountData, isError: isErrorOwnerUnitCaseCountData, refetch: refetchOwnerUnitCaseCountData } = useQuery({
    queryFn: () => getOwnerUnitCaseCount(formattedMinDate, formattedMaxDate),
    queryKey: ['ownerUnitCaseCountData', formattedMinDate, formattedMaxDate],
    refetchOnWindowFocus: false,
    staleTime: 60000
  })

  const { data: categorySatisfactionData, isLoading: isLoadingCategorySatisfactionData, isError: isErrorCategorySatisfactionData, refetch: refetchCategorySatisfactionData } = useQuery({
    queryFn: () => getCategorySatisfaction(formattedMinDate, formattedMaxDate),
    queryKey: ['categorySatisfactionData', formattedMinDate, formattedMaxDate],
    refetchOnWindowFocus: false,
    staleTime: 60000
  })

  const { data: ownerUnitSatisfactionData, isLoading: isLoadingOwnerUnitSatisfactionData, isError: isErrorOwnerUnitSatisfactionData, refetch: refetchOwnerUnitSatisfactionData } = useQuery({
    queryFn: () => getOwnerUnitSatisfaction(formattedMinDate, formattedMaxDate),
    queryKey: ['ownerUnitSatisfactionData', formattedMinDate, formattedMaxDate],
    refetchOnWindowFocus: false,
    staleTime: 60000
  })

  const { data: reportRankCasesExcelData, refetch: refetchReportRankCasesExcelData } = useQuery({
    queryFn: () => getReportCasesExcelData(formattedMinDate, formattedMaxDate, subOwnerUnitsSelected, subCategoriesSelected),
    enabled: false,
    queryKey: ['reportRankCasesExcelData', subOwnerUnitsSelected, subCategoriesSelected],
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    clearOwnerUnitSelected()
    clearSubCategorySelected()
    setTriggerFromOtherPage(true)
  }, [])

  // 排行案件 Excel 匯出
  useEffect(() => {
    if (triggerExport) {
      refetchReportRankCasesExcelData()
      setTriggerExport(false)
    }
    
    if (reportRankCasesExcelData && !triggerFromOtherPage) {
      try {
        const url = window.URL.createObjectURL(reportRankCasesExcelData);
        const a = document.createElement('a');
        a.href = url;
        a.download = `report-rank-case-${formattedMinDate}-${formattedMaxDate}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } catch (err) {
        console.error(err);
      }
    }
  }, [triggerExport, reportRankCasesExcelData, triggerFromOtherPage])

  useEffect(() => {
    if (categoryCaseCountData) {
      // console.log('CategoryCaseCount', categoryCaseCountData.data.data.sub_category);
      setCategoryCaseCount(categoryCaseCountData.data.data.sub_category)
    }

    if (ownerUnitCaseCountData) {
      // console.log('ownerUnitCaseCount', ownerUnitCaseCountData.data.data.owner_unit);
      setOwnerUnitCaseCount(ownerUnitCaseCountData.data.data.owner_unit)
    }

    if (categorySatisfactionData) {
      // console.log('categorySatisfaction', categorySatisfactionData.data.data);
      setCategorySatisfaction(categorySatisfactionData.data.data)
    }

    if (ownerUnitSatisfactionData) {
      // console.log('ownerUnitSatisfaction', ownerUnitSatisfactionData.data.data);
      setOwnerUnitSatisfaction(ownerUnitSatisfactionData.data.data)
    }
  }, [formattedMinDate, formattedMaxDate, categoryCaseCountData, ownerUnitCaseCountData, categorySatisfactionData, ownerUnitSatisfactionData])

  useEffect(() => {
    if (caseFirstChunkData) {
      setCaseData(caseFirstChunkData)
      clearOwnerUnitSelected()
      clearSubCategorySelected()
      clearSearchSelected()
      updateSatisfaction([])
    }
  }, [caseFirstChunkData])

  const handleCategoryTypeChange = (e, newValue) => {
    setCategoryType(newValue)
  }

  const handleRankTypeChange = (e, newValue) => {
    setRankType(newValue)
  }

  const handlePanelExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (!categoryCaseCountData || isErrorCategoryCaseCountData) {
    refetchCategoryCaseCountData()
  }

  if (!ownerUnitCaseCountData || isErrorOwnerUnitCaseCountData) {
    refetchOwnerUnitCaseCountData()
  }

  if (!categorySatisfactionData || isErrorCategorySatisfactionData) {
    refetchCategorySatisfactionData()
  }

  if (!ownerUnitSatisfactionData || isErrorOwnerUnitSatisfactionData) {
    refetchOwnerUnitSatisfactionData()
  }

  return (
    <Paper 
      sx={{
        m: 2,
        borderRadius: '16px',
        height: 'auto',
        boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.05)'
      }}
    >
      <Stack
        sx={{
          pt: '20px',
          pb: '24px',
          borderBottom: (theme) => `1px solid ${theme.palette.neutral.light}`
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h3"
            sx={{
              pl: '16px',
              mr: '16px',
              borderLeft: '4px solid #52697A',
              fontWeight: 400
            }}
          >
            排行統計分析
          </Typography>
          <Stack>
            <Tabs
              value={rankType}
              onChange={handleRankTypeChange}
              textColor="secondary"
              indicatorColor="transparent"
              aria-label="secondary tabs example"
              sx={{
                backgroundColor: (theme) => theme.palette.bg.grey,
                width: 'fit-content',
                padding: '4px',
                borderRadius: '99px',
                '&.MuiTabs-root': {
                  minHeight: 'auto'
                }
              }}
            >
              <CustomRankTab value="times" label="次數排行" />
              <CustomRankTab value="dissatisfaction" label="不滿意排行" />
            </Tabs>
          </Stack>
        </Box>
      </Stack>
      <Box
        sx={{
          m: '20px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Tabs
          value={categoryType}
          onChange={handleCategoryTypeChange}
          textColor="secondary"
          indicatorColor="transparent"
          aria-label="secondary tabs example"
          sx={{
            backgroundColor: (theme) => theme.palette.bg.white,
            width: 'fit-content',
            '&.MuiTabs-root': {
              minHeight: 'auto'
            }
          }}
        >
          <CustomTab value="category" label="案件分類" />
          <CustomTab value="ownerUnit" label="機關分類" />
        </Tabs>
        <Typography
          variant='h5'
          sx={{
            fontWeight: 400,
            color: theme => theme.palette.neutral.dark
          }}
        >
          總計
          <span 
            style={{ 
              color: '#52697A', 
              margin: '0 4px',
              fontSize: '20px',
              lineHeight: '30px',
              fontWeight: 700
            }}
          >
            {rankType === 'times' ? 
              categoryCaseCountData?.data.data.total_count.toLocaleString() :
              categorySatisfaction?.total_count.toLocaleString()
            }
          </span> 
          件
        </Typography>
      </Box>

      {rankType === 'times' ? 
        <Stack
          sx={{
            p: '0 20px 20px'
          }}
        >
          {(categoryType === 'category' ? categoryCaseCount : ownerUnitCaseCount)?.map((item, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handlePanelExpand(`panel${index}`)}
              sx={{
                '&.MuiAccordion-root.Mui-expanded': {
                  margin: 0
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
                sx={{
                  backgroundColor: index % 2 === 0 ? '#FAFAFA' : '#F5F5F5'
                }}
              >
                <Grid container spacing={0} alignItems="center">
                  <Grid item width={54}>
                    <Box
                      sx={{
                        width: '30px',
                        height: '30px',
                        backgroundColor: '#ffffff',
                        border: '1px solid #D4D4D4',
                        borderRadius: '50%',
                      }}
                    >
                      <Typography
                        sx={{
                          lineHeight: '30px',
                          textAlign: 'center',
                          fontSize: '14px',
                          fontWeight: 'bold',
                          color: '#404040'
                        }}
                      >
                        {index + 1}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item width={320}>
                    {categoryType === 'category' && (
                      <Typography 
                        variant="h5"
                        sx={{
                          fontWeight: 400,
                          color: theme => theme.palette.neutral.dark
                        }}
                      >
                        {item.key}
                      </Typography>
                    )}
                    <Typography 
                      sx={{
                        color: theme => categoryType === 'category' ? `${theme.palette.info.main}` : `${theme.palette.neutral.dark}`,
                        fontWeight: categoryType === 'category' ? 700 : 400,
                        fontSize: categoryType === 'category' ? '10px' : '20px',
                        lineHeight: categoryType === 'category' ? '15px' : '30px'
                      }}
                    >
                      {categoryType === 'category' ? item.main_category : item.key}
                    </Typography>
                  </Grid>
                  <Grid item flex={1}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography 
                        variant="h4" 
                        sx={{
                          color: theme => theme.palette.info.main,
                          fontWeight: 'bold'
                        }}
                      >
                        {categoryType === 'category' ? `${(item.percent_by_total * 100).toFixed(1)}%` : `${(item.percent * 100).toFixed(1)}%`}
                      </Typography>
                      <Typography 
                        variant="h5" 
                        sx={{
                          color: theme => theme.palette.neutral.silver,
                          fontWeight: 'bold',
                          mt: '10px'
                        }}
                      >
                        {`${item.doc_count}件`}
                      </Typography>
                    </Box>
                    <RankBarChart
                      value={categoryType === 'category' ? item.percent_by_total : item.percent}
                      type='times_mainKey'
                    />
                  </Grid>
                  <Grid 
                    item 
                    width={156}
                    sx={{
                      p: '0 12px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Link 
                      to={`/dashboard/case-analysis?triggerSearch=true`}
                      onClick={() => {
                        clearOwnerUnitSelected()
                        clearSubCategorySelected()
                        categoryType === 'category' && updateSubCategorySelected(
                          item.main_category, 
                          item.key, 
                          true
                        )
                        categoryType !== 'category' && updateOwnerUnitSelected(true, item.key, caseData)
                      }}
                    >
                      <RankLinkIcon fill='#737373' />
                    </Link>
                    <Button
                      onClick={e => { 
                        e.stopPropagation()
                        setTriggerExport(true)
                        setTriggerFromOtherPage(false)
                        if (categoryType === 'category') {
                          clearOwnerUnitSelected()
                          clearSubCategorySelected()
                          updateSubCategorySelected(
                            item.main_category, 
                            item.key, 
                            true
                          )
                        } else {
                          clearOwnerUnitSelected()
                          clearSubCategorySelected()
                          updateOwnerUnitSelected(true, item.key, caseData)
                        }
                      }}
                      sx={{
                        width: 'auto',
                        background: '#FFFFFF',
                        border: '1px solid #ACBCC8',
                        color: '#0A0A0A',
                        padding: '8px 20px',
                        margin: '0 8px',
                        fontWeight: 'bold',
                        borderRadius: '99px',
                        justifyContent: 'space-between',
                        '&:hover': {
                          background: '#F0F3F5',
                        },
                        '&:focus': {
                          background: '#F0F3F5',
                          border: '1px solid #52697A'
                        }
                      }}
                    >
                      <span style={{ marginRight: '4px' }}>匯出</span>
                      <ExportIcon fill='#0A0A0A' />
                    </Button>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  p: 0
                }}
              >
                <Box 
                  sx={{ 
                    width: '100%',
                    backgroundColor: theme => theme.palette.bg.dark,
                  }}
                >
                  <Grid 
                    container 
                    spacing={0} 
                    alignItems="center" 
                    sx={{ 
                      mt: 0,
                      display: 'flex'
                    }}
                  >
                    {(categoryType === 'category' ? item.owner_unit : item.sub_category).map((subItem, subIndex) => (
                      <Box
                        key={subIndex}
                        sx={{
                          width: 'calc(100% / 5)',
                          p: '12px 20px'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <Box
                            sx={{
                              width: '20px',
                              height: '20px',
                              backgroundColor: '#ffffff',
                              border: '1px solid #D4D4D4',
                              borderRadius: '50%',
                              mr: '4px'
                            }}
                          >
                            <Typography
                              sx={{
                                lineHeight: '20px',
                                textAlign: 'center',
                                fontSize: '10px',
                                fontWeight: 'bold',
                                color: '#404040'
                              }}
                            >
                              {subIndex + 1}
                            </Typography>
                          </Box>
                          {categoryType === 'category' ? (
                            <Typography 
                              variant="h5"
                              sx={{
                                fontWeight: 400,
                                color: theme => theme.palette.neutral.dark,
                                mr: '4px'
                              }}
                            >
                              {subItem.key}
                            </Typography>
                          ) : (
                            <Box
                              sx={{
                                mr: '5px'
                              }}
                            >
                              <Typography 
                                variant="h5"
                                sx={{
                                  fontWeight: 400,
                                  color: theme => theme.palette.neutral.dark
                                }}
                              >
                                {subItem.key}
                              </Typography>
                              <Typography 
                                sx={{
                                  color: theme => categoryType === 'category' ? `${theme.palette.info.main}` : '#52697A',
                                  fontWeight: 'bold',
                                  fontSize: '10px',
                                  lineHeight: '15px'
                                }}
                              >
                                {subItem.main_category}
                              </Typography>
                            </Box>
                          )
                          }
                          <Typography
                            sx={{
                              fontSize: '10px',
                              lineHeight: '15px',
                              color: '#52697A',
                              fontWeight: 700
                            }}
                          >
                            {`${subItem.doc_count} 件`}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <RankBarChart
                            value={categoryType === 'category' ? subItem.percent : subItem.percent_by_owern_unit}
                            type='times_subKey'
                          />
                          <Link 
                            to={`/dashboard/case-analysis?triggerSearch=true`}
                            onClick={() => {
                              clearOwnerUnitSelected()
                              clearSubCategorySelected()
                              updateOwnerUnitSelected(true, categoryType === 'category' ? subItem.key : item.key, caseData)
                              updateSubCategorySelected(
                                categoryType === 'category' ? item.main_category : subItem.main_category, 
                                categoryType === 'category' ? item.key : subItem.key, 
                                true
                              )
                            }}
                          >
                            <RankLinkIcon fill='#737373' />
                          </Link>
                        </Box>
                      </Box>
                    ))}
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>
        :
        <Stack
          sx={{
            p: '0 20px 20px',
            display: 'flex',
            flexFlow: 'row wrap',
            gap: '0 12px'
          }}
        >
          <Stack
            sx={{
              width: 'calc((100% - 24px) / 3)'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '12px',
                backgroundColor: theme => theme.palette.primary.light,
                borderBottom: '2px solid #E95076',
                borderRadius: '8px 8px 0 0'
              }}
            >
              <Typography
                variant='h4'
                sx={{
                  color: theme => theme.palette.neutral.dark,
                  fontWeight: 400
                }}
              >
                總計
              </Typography>
              <Typography
                variant='h5'
                sx={{
                  color: theme => theme.palette.neutral.silver,
                  fontWeight: 700
                }}
              >
                {categoryType === 'category' ? 
                categorySatisfaction?.sorted_by_total_dissatisfaction?.dissatisfaction_count : 
                ownerUnitSatisfaction?.sorted_by_total_dissatisfaction?.dissatisfaction_count
                } 
                <span style={{ fontWeight: 400, marginLeft: '4px' }}>件</span>
              </Typography>
            </Box>
            {(categoryType === 'category' ? 
              categorySatisfaction?.sorted_by_total_dissatisfaction?.data : 
              ownerUnitSatisfaction?.sorted_by_total_dissatisfaction?.data)?.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    width: '100%',
                    p: '12px',
                    backgroundColor: theme => index % 2 === 0 ? theme.palette.bg.light : theme.palette.bg.grey
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Box
                      sx={{
                        width: '30px',
                        height: '30px',
                        backgroundColor: '#ffffff',
                        border: '1px solid #D4D4D4',
                        borderRadius: '50%',
                        mr: '4px'
                      }}
                    >
                      <Typography
                        sx={{
                          lineHeight: '30px',
                          textAlign: 'center',
                          fontSize: '14px',
                          fontWeight: 'bold',
                          color: '#404040'
                        }}
                      >
                        {index + 1}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        flex: 1
                      }}
                    >
                      {categoryType === 'category' ? (
                        <>
                          <Typography 
                            variant="h5"
                            sx={{
                              fontWeight: 400,
                              color: theme => theme.palette.neutral.dark
                            }}
                          >
                            {item.sub_category}
                          </Typography>
                          <Typography 
                            sx={{
                              color: '#52697A',
                              fontWeight: 700,
                              fontSize: '10px',
                              lineHeight: '15px'
                            }}
                          >
                            {item.main_category}
                          </Typography>
                        </>
                      ) : (
                        <Typography 
                          variant='h4'
                          sx={{
                            color: theme => theme.palette.neutral.dark,
                            fontWeight: 400,
                          }}
                        >
                          {item.owner_unit}
                        </Typography>
                      )}
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Box
                          sx={{
                            width: '90%'
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between'
                            }}
                          >
                            <Typography
                              variant='h4'
                              sx={{
                                color: '#52697A'
                              }}
                            >
                              {`${Math.round(item.dissatisfaction_percent * 100)}%`}
                            </Typography>
                            <Typography
                              variant='h5'
                              sx={{
                                color: theme => theme.palette.neutral.silver,
                                fontWeight: 'bold'
                              }}
                            >
                              {`${item.dissatisfaction_count} 件`}
                            </Typography>
                          </Box>
                          <RankBarChart
                            value={item.dissatisfaction_percent}
                            type='satisfaction_total'
                          />
                        </Box>
                        <Link 
                          to={`/dashboard/case-analysis?triggerSearch=true`}
                          onClick={() => {
                            clearOwnerUnitSelected()
                            clearSubCategorySelected()
                            if (categoryType === 'category') {
                              updateSubCategorySelected(item.main_category, item.sub_category, true)
                              updateSatisfaction(['非常不滿意', '不滿意'])
                            } else if (categoryType !== 'category') {
                              updateOwnerUnitSelected(true, item.owner_unit, caseData)
                              updateSatisfaction(['非常不滿意', '不滿意'])
                            }
                          }}
                        >
                          <RankLinkIcon fill='#737373' />
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                  
                </Box>
              ))}
          </Stack>
          <Stack
            sx={{
              width: 'calc((100% - 24px) / 3)'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '12px',
                backgroundColor: theme => theme.palette.primary.light,
                borderBottom: '2px solid #E95076',
                borderRadius: '8px 8px 0 0'
              }}
            >
              <Typography
                variant='h4'
                sx={{
                  color: theme => theme.palette.neutral.dark,
                  fontWeight: 400
                }}
              >
                非常不滿意
              </Typography>
              <Typography
                variant='h5'
                sx={{
                  color: theme => theme.palette.neutral.silver,
                  fontWeight: 700
                }}
              >
                {categoryType === 'category' ? 
                categorySatisfaction?.sorted_by_very_dissatisfied?.dissatisfaction_count :
                ownerUnitSatisfaction?.sorted_by_very_dissatisfied?.dissatisfaction_count
                }
                <span style={{ fontWeight: 400, marginLeft: '4px' }}>件</span>
              </Typography>
            </Box>
            {(categoryType === 'category' ? 
              categorySatisfaction?.sorted_by_very_dissatisfied?.data : 
              ownerUnitSatisfaction?.sorted_by_very_dissatisfied?.data)?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  width: '100%',
                  p: '12px',
                  backgroundColor: theme => index % 2 === 0 ? theme.palette.bg.light : theme.palette.bg.grey
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Box
                    sx={{
                      width: '30px',
                      height: '30px',
                      backgroundColor: '#ffffff',
                      border: '1px solid #D4D4D4',
                      borderRadius: '50%',
                      mr: '4px'
                    }}
                  >
                    <Typography
                      sx={{
                        lineHeight: '30px',
                        textAlign: 'center',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        color: '#404040'
                      }}
                    >
                      {index + 1}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      flex: 1
                    }}
                  >
                    {categoryType === 'category' ? (
                      <>
                        <Typography 
                          variant="h5"
                          sx={{
                            fontWeight: 400,
                            color: theme => theme.palette.neutral.dark
                          }}
                        >
                          {item.sub_category}
                        </Typography>
                        <Typography 
                          sx={{
                            color: theme => theme.palette.info.main,
                            fontWeight: 700,
                            fontSize: '10px',
                            lineHeight: '15px'
                          }}
                        >
                          {item.main_category}
                        </Typography>
                      </>
                    ) : (
                      <Typography 
                        variant='h4'
                        sx={{
                          color: theme => theme.palette.neutral.dark,
                          fontWeight: 400,
                        }}
                      >
                        {item.owner_unit}
                      </Typography>
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Box
                        sx={{
                          width: '90%'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Typography
                            variant='h4'
                            sx={{
                              color: theme => theme.palette.info.main
                            }}
                          >
                            {`${Math.round(item.dissatisfaction_percent * 100)}%`}
                          </Typography>
                          <Typography
                            variant='h5'
                            sx={{
                              color: theme => theme.palette.neutral.silver,
                              fontWeight: 'bold'
                            }}
                          >
                            {`${item.dissatisfaction_count} 件`}
                          </Typography>
                        </Box>
                        <RankBarChart
                          value={item.dissatisfaction_percent}
                          type='satisfaction_very_dissatisfaction'
                        />
                      </Box>
                      <Link 
                        to={`/dashboard/case-analysis?triggerSearch=true`}
                        onClick={() => {
                          clearOwnerUnitSelected()
                          clearSubCategorySelected()
                          if (categoryType === 'category') {
                            updateSubCategorySelected(item.main_category, item.sub_category, true)
                            updateSatisfaction(['非常不滿意'])
                          } else if (categoryType !== 'category') {
                            updateOwnerUnitSelected(true, item.owner_unit, caseData)
                            updateSatisfaction(['非常不滿意'])
                          }
                        }}
                      >
                        <RankLinkIcon fill='#737373' />
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Stack>
          <Stack
            sx={{
              width: 'calc((100% - 24px) / 3)'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '12px',
                backgroundColor: theme => theme.palette.primary.light,
                borderBottom: '2px solid #E95076',
                borderRadius: '8px 8px 0 0'
              }}
            >
              <Typography
                variant='h4'
                sx={{
                  color: theme => theme.palette.neutral.dark,
                  fontWeight: 400
                }}
              >
                不滿意
              </Typography>
              <Typography
                variant='h5'
                sx={{
                  color: theme => theme.palette.neutral.silver,
                  fontWeight: 'bold'
                }}
              >
                {categoryType === 'category' ? 
                categorySatisfaction?.sorted_by_dissatisfied?.dissatisfaction_count :
                ownerUnitSatisfaction?.sorted_by_dissatisfied?.dissatisfaction_count
                }
                <span style={{ fontWeight: 400, marginLeft: '4px' }}>件</span>
              </Typography>
            </Box>
            {(categoryType === 'category' ? 
              categorySatisfaction?.sorted_by_dissatisfied?.data : 
              ownerUnitSatisfaction?.sorted_by_dissatisfied?.data)?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  width: '100%',
                  p: '12px',
                  backgroundColor: theme => index % 2 === 0 ? theme.palette.bg.light : theme.palette.bg.grey
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Box
                    sx={{
                      width: '30px',
                      height: '30px',
                      backgroundColor: '#ffffff',
                      border: '1px solid #D4D4D4',
                      borderRadius: '50%',
                      mr: '4px'
                    }}
                  >
                    <Typography
                      sx={{
                        lineHeight: '30px',
                        textAlign: 'center',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        color: '#404040'
                      }}
                    >
                      {index + 1}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      flex: 1
                    }}
                  >
                    {categoryType === 'category' ? (
                      <>
                        <Typography 
                          variant="h5"
                          sx={{
                            fontWeight: 400,
                            color: theme => theme.palette.neutral.dark
                          }}
                        >
                          {item.sub_category}
                        </Typography>
                        <Typography 
                          sx={{
                            color: theme => theme.palette.info.main,
                            fontWeight: 700,
                            fontSize: '10px',
                            lineHeight: '15px'
                          }}
                        >
                          {item.main_category}
                        </Typography>
                      </>
                    ) : (
                      <Typography 
                        variant='h4'
                        sx={{
                          color: theme => theme.palette.neutral.dark,
                          fontWeight: 400,
                        }}
                      >
                        {item.owner_unit}
                      </Typography>
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Box
                        sx={{
                          width: '90%'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Typography
                            variant='h4'
                            sx={{
                              color: theme => theme.palette.info.main
                            }}
                          >
                            {`${Math.round(item.dissatisfaction_percent * 100)}%`}
                          </Typography>
                          <Typography
                            variant='h5'
                            sx={{
                              color: theme => theme.palette.neutral.silver,
                              fontWeight: 'bold'
                            }}
                          >
                            {`${item.dissatisfaction_count} 件`}
                          </Typography>
                        </Box>
                        <RankBarChart
                          value={item.dissatisfaction_percent}
                          type='satisfaction_dissatisfaction'
                        />
                      </Box>
                      <Link 
                        to={`/dashboard/case-analysis?triggerSearch=true`}
                        onClick={() => {
                          clearOwnerUnitSelected()
                          clearSubCategorySelected()
                          if (categoryType === 'category') {
                            updateSubCategorySelected(item.main_category, item.sub_category, true)
                            updateSatisfaction(['不滿意'])
                          } else if (categoryType !== 'category') {
                            updateOwnerUnitSelected(true, item.owner_unit, caseData)
                            updateSatisfaction(['不滿意'])
                          }
                        }}
                      >
                        <RankLinkIcon fill='#737373' />
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Stack>
        </Stack>
      }
    </Paper>
)
}

export default RankStatistics