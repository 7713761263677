
import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import { useQuery } from '@tanstack/react-query'
import { 
  Stack, 
  Paper, 
  Box, 
  Typography, 
  Skeleton, 
} from '@mui/material'
import { getHomeData } from '@/api/getHomeDataAPI'
import { getWordCloud } from '@/api/getWordCloudAPI'
import UseDateRangeStore from "@/hooks/UseDateRangeStore";
import { transformChartDate } from '@/utils/transformChartDate'
import LineChart from '@/components/LineChart';
import CustomBarChart from '@/components/CustomBarChart';
import WordCloudChart from '@/components/WordCloudChart';

function Home() {
  const [ homeData, setHomeData ] = useState({});

  const { formattedMinDate, formattedMaxDate } = UseDateRangeStore()
  const { data: homePageData, isLoading: isLoadingHomeData, isError: isErrorHomeData, refetch: refetchHomeData } = useQuery({
    queryFn: () => getHomeData(formattedMinDate, formattedMaxDate),
    queryKey: ['homeData', formattedMinDate, formattedMaxDate],
    refetchOnWindowFocus: false,
    staleTime: 60000
  })

  const { data: wordCloudData, isLoading: isLoadingWordCloudData, isError: isErrorWordCloudData, refetch: refetchWordCloudData } = useQuery({
    queryFn: () => getWordCloud(formattedMinDate, formattedMaxDate),
    queryKey: ['wordCloudData', formattedMinDate, formattedMaxDate],
    refetchOnWindowFocus: false,
    staleTime: 60000
  })

  // 切換日期，重新獲取資料
  useEffect(() => {
    // console.log('formattedMinDate', formattedMinDate);
    // console.log('formattedMaxDate', formattedMaxDate);

    if (homePageData) {
      // console.log('homeData', homePageData.data.data)
      setHomeData(homePageData.data.data)
    }
  }, [formattedMinDate, formattedMaxDate, homePageData]);


  if (!homePageData) {
    refetchHomeData()
  }

  if (!wordCloudData) {
    refetchWordCloudData()
  }

  return (
    <>
      <Paper 
        sx={{
          m: 2,
          mb: '12px',
          borderRadius: '16px',
          height: 'auto',
          boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.05)'
        }}
      >
        <Stack
          sx={{
            pt: '20px',
            borderBottom: (theme) => `1px solid ${theme.palette.neutral.light}`
          }}
        >
          <Box>
            <Typography
              variant="h3"
              sx={{
                pl: '16px',
                mr: '16px',
                borderLeft: '4px solid #52697A',
                fontWeight: 400
              }}
            >
              每日案件數量趨勢
            </Typography>
            
            <Box 
              sx={{ 
                width: '100%',
                p: '0 20px'
              }}
            >
              {isLoadingHomeData &&
                <Skeleton 
                  variant="rectangular" 
                  sx={{
                    width: '100%',
                    height: '400px',
                    margin: '20px auto',
                    borderRadius: '20px',
                    backgroundColor: '#bbb'
                  }}
                  animation='pulse'
                  animationspeed='slow'
                />
              }
              {homeData && homeData.total_count !== 0 && !isLoadingHomeData && (
                <>
                  <Typography
                    variant='h5'
                    sx={{
                      textAlign: 'right',
                      p: '0 10px',
                      fontWeight: 400
                    }}
                  >
                    總計
                    <span 
                      style={{ 
                        color: '#E95076', 
                        margin: '4px',
                        fontSize: '20px',
                        fontWeight: 700,
                        lineHeight: '30px'
                      }}
                    >
                      {homeData?.total_count?.toLocaleString()}
                    </span> 
                    件
                  </Typography>
                  <LineChart
                    categories={homeData?.daily_count && Object.keys(homeData?.daily_count)?.map((date) => date)}
                    series={[
                      {
                        name: '每日案件',
                        data: homeData?.daily_count && Object.values(homeData?.daily_count),
                        color: '#E95076',
                        lineWidth: 1.5,
                      },
                    ]}
                    xAxis={{
                      labels: {
                        style: {
                          color: '#0A0A0A',
                          fontSize: '10px',
                          lineHeight: '15px'
                        },
                      }
                    }}
                    yAxis={{
                      tickInterval: 25
                    }}
                    plotOptions={{
                      series: {
                        marker: {
                          enabled: true,
                          radius: 4,
                          fillColor: '#FFFFFF',
                          lineWidth: 2,
                          lineColor: null
                        }
                      }
                    }}
                    gradient={{
                      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                      stops: [
                        [0, Highcharts.color('#E95076').setOpacity(0.2).get('rgba')],
                        [1, Highcharts.color('#E95076').setOpacity(0).get('rgba')]
                      ]
                    }}
                  />
                </>
              )}
            </Box>
          </Box>
        </Stack>
      </Paper>
      <Stack
        sx={{
          display: 'flex',
          flexFlow: 'row wrap',
          mb: '16px',
          p: '0 16px'
        }}
      >
        <Stack
          sx={{
            mr: '12px',
            width: 'calc((100% - 12px) / 2)'
          }}
        >
          <Paper 
            sx={{
              borderRadius: '16px',
              height: 'auto',
              boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.05)'
            }}
          >
            <Stack
              sx={{
                pt: '20px',
              }}
            >
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    pl: '16px',
                    mr: '16px',
                    borderLeft: '4px solid #52697A',
                    fontWeight: 400
                  }}
                >
                  熱門分類
                </Typography>
                
                <Box 
                  sx={{ 
                    width: '100%',
                    p: '0 20px 8px 20px'
                  }}
                >
                  {isLoadingHomeData &&
                    <Skeleton 
                      variant="rectangular" 
                      sx={{
                        width: 'calc(100%)',
                        height: '230px',
                        margin: '10px auto',
                        borderRadius: '20px',
                        backgroundColor: '#bbb'
                      }}
                      animation='pulse'
                      animationspeed='slow'
                    />
                  }
                  {homeData && homeData.category_count && !isLoadingHomeData && (
                    <CustomBarChart data={homeData.category_count} />
                  )}
                </Box>
              </Box>
            </Stack>
          </Paper>
          <Paper 
            sx={{
              mt: '12px',
              borderRadius: '16px',
              height: 'auto',
              boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.05)'
            }}
          >
            <Stack
              sx={{
                pt: '20px',
              }}
            >
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    pl: '16px',
                    mr: '16px',
                    borderLeft: '4px solid #52697A',
                    fontWeight: 400
                  }}
                >
                  熱門機關
                </Typography>
                
                <Box 
                  sx={{ 
                    width: '100%',
                    p: '0 20px 8px 20px'
                  }}
                >
                  {isLoadingHomeData &&
                    <Skeleton 
                      variant="rectangular" 
                      sx={{
                        width: 'calc(100%)',
                        height: '230px',
                        margin: '10px auto',
                        borderRadius: '20px',
                        backgroundColor: '#bbb'
                      }}
                      animation='pulse'
                      animationspeed='slow'
                    />
                  }
                  {homeData && homeData.owner_unit_count && !isLoadingHomeData && (
                    <CustomBarChart data={homeData.owner_unit_count} />
                  )}
                </Box>
              </Box>
            </Stack>
          </Paper>
        </Stack>

        {/* 文字雲 */}
        <Paper 
          sx={{
            width: 'calc((100% - 12px) / 2)',
            borderRadius: '16px',
            height: 'auto',
            boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.05)'
          }}
        >
          <Stack
            sx={{
              pt: '20px',
            }}
          >
            <Box>
              <Typography
                variant="h3"
                sx={{
                  pl: '16px',
                  mr: '16px',
                  borderLeft: '4px solid #52697A',
                  fontWeight: 400
                }}
              >
                關鍵字文字雲
              </Typography>
              
              <Box 
                sx={{ 
                  width: '100%',
                  height: '550px',
                  p: '0',
                  display: 'flex',
                  flexFlow: 'row wrap',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {isLoadingWordCloudData &&
                  <Skeleton 
                    variant='circular' 
                    sx={{
                      width: '80%',
                      height: '400px',
                      margin: '30px',
                      backgroundColor: '#ccc'
                    }}
                    animation='pulse'
                    animationspeed='slow'
                  />
                }
                {wordCloudData && wordCloudData.data.data && !isLoadingWordCloudData && (
                  <WordCloudChart data={wordCloudData.data.data} />
                )}
              </Box>
            </Box>
          </Stack>
        </Paper>
      </Stack>
    </>
  )
}

export default Home